import './scripts/publicPath'
import 'console-polyfill'
import 'normalize.css/normalize.css'
import './main.scss'
import $ from 'jquery'
import feather from 'feather-icons'

import installCE from 'document-register-element/pony'

window.jQuery = $

window.lazySizesConfig = window.lazySizesConfig || {}
window.lazySizesConfig.preloadAfterLoad = true
require('lazysizes')

$(document).ready(function () {
  feather.replace({
    'stroke-width': 1
  })
  function checkScroll() {
    var scroll = $(window).scrollTop();
    if (scroll >= 75) {
        $(".mainHeader").addClass("stuck");
    }
    else {
      $(".mainHeader").removeClass("stuck");
    }
  }
  $(window).scroll(function() {    
    checkScroll();
  });
  checkScroll();
})

installCE(window, {
  type: 'force',
  noBuiltIn: true
})

function importAll (r) {
  r.keys().forEach(r)
}

importAll(require.context('../Components/', true, /\/script\.js$/))
